@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    font-family: inherit;
  }

  html {
    @apply box-border scroll-smooth;
  }

  body {
    @apply font-NotoSans leading-normal text-sm text-black bg-grey-light;
  }

  h1 {
    @apply text-xl text-accent-dark;
  }

  h2 {
    @apply text-lg text-grey-dark text-center;
  }

  h3 {
    @apply text-md text-black;
  }

  h4 {
    @apply text-lg text-black;
  }
}

@layer components {
  .nav-link {
    @apply uppercase text-center px-4 py-1 border-b-4 border-transparent hover:border-accent-main transition-all;
  }

  .item {
    @apply w-full p-4 flex items-center justify-between gap-4 bg-white shadow-md rounded-md;
  }

  .subparagraph {
    @apply text-xs text-grey-dark;
  }

  .rounded-button {
    @apply w-12 h-12 flex items-center justify-center border border-grey-main rounded-full hover:border-accent-light transition-all text-grey-dark hover:text-accent-main;
  }

  .classic-button {
    @apply flex items-center justify-center px-5 py-3 bg-white border rounded-lg  border-grey-main text-grey-dark hover:text-accent-main hover:shadow-md active:shadow-sm transition-all;
  }

  .error-validation {
    @apply text-xs text-accent-main ml-3;
  }

  .text-input {
    @apply px-3 py-2 text-sm text-black outline-none rounded placeholder:font-extralight;
  }
}

@layer utilities {
  .flex-row-aligned {
    @apply flex items-center;
  }

  .flex-col-start {
    @apply flex flex-col items-start;
  }
}
